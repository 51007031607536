@use 'sass:color';
@use '@angular/material' as mat;

@use 'usic-mat-theme-V2' as theme;

$color-primary: #00305B;
$color-primary-dark: #015aa7;
$color-accent: #95c93d;
$color-warn: #F25C0F;

:root {
  --default-font: Noto Sans KR, sans-serif;
  --usic-blue: #{$color-primary};
  --usic-blue-dark: #{$color-primary-dark};
  --usic-blue-rgb: #{hexToRGB($color-primary)};
  --usic-green: #{$color-accent};
  --usic-green-rgb: #{hexToRGB($color-accent)};
  --usic-red: #{$color-warn};
  --usic-red-rgb: #{hexToRGB($color-warn)};
  --usic-grey: #7f7c7f;
  --usic-light-grey: #fafafa;
  --usic-white: #fefefe;
  --usic-black: #1c1717;
  @media (prefers-color-scheme: dark) {
    color-scheme: dark;
  }
}

/* responsive classes */
.hide { display: none!important; }

/*  ORIG - projects/usic-lib/src/lib/styles.scss */
*:focus {
  outline: none !important;
}

html,
body {
  height: 100%
}

body {
  font-family : var(--default-font);
  margin : 0;

  background-color: mat.m2-get-color-from-palette(theme.$usic-surface, "lighter");

  @media (prefers-color-scheme: dark) {
    background-color: mat.m2-get-color-from-palette(theme.$usic-surface, "darker");
  }

  @media screen AND (max-width: 600px) {
    font-size: smaller;
  }
}

/* typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 .25em;
}

h1 {
  // PAGE TITLES
  @extend .mat-headline !optional;
  margin-top : .5em;
  font-size: 28px;

  color: rgba(0, 0, 0, .6);

  @media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, .6);
  }
}

@media screen AND (max-width: 600px) {
  h1 {
    font-size: 1.2rem;
  }
}

h2 {
  // PAGE SUBTITLES - USIC GREEN
  @extend .mat-title !optional;
  color: var(--usic-green);
  text-transform: uppercase;

  @media screen AND (max-width: 600px) {
    // Normally 1.5em
    font-size: 1em;
  }
}

h3 {
  // Subtitles - USIC Blue
  @extend .mat-subheading-2 !optional;

  margin-bottom : 1em;

  color: #{$color-primary};

  @media (prefers-color-scheme: dark) {
    color: #{$color-primary-dark};
  }

  @media screen AND (max-width: 600px) {
    // Normally 1.17em
    font-size: 0.85em;
  }

}

h4 {
  @extend .mat-subheading-1 !optional;
}

p {
  @extend .mat-body-1 !optional;
  margin-bottom: 0;

  @media screen AND (max-width: 600px) {
    font-size: small;
  }
}

@media screen AND (max-width: 600px) {
  pre {
    font-size: small;
  }
}

dl {
  @extend .mat-caption !optional;
  font-weight: normal;

  dd {
    margin: .25em 1em .5em;
  }
}

.mat-mdc-cell {
  // td
  font-size: .875rem;
  font-weight: 400;
  letter-spacing: .038em;

  @media screen AND (max-width: 600px) {
    font-size: .75rem;
  }
}

a,
a:link,
a:visited {
  transition: all 0.35s;
  text-decoration: none;
  background-color: transparent;
  touch-action: manipulation;

  color: var(--usic-blue);

  @media (prefers-color-scheme: dark) {
    color: var(--usic-green);
  }

  &:hover {
    opacity: .6
  }

  &.cursor-pointer {
    cursor: pointer;
  }
}

fieldset {
  @extend .border;
  margin-bottom: 2rem;
  padding: 1.4em 1.4em 0;

  & legend {
    font-size: .875rem;
    font-weight: 500;
    text-transform: uppercase;
  }
}

hr {
  border-color: var(--usic-grey);
  border-width: 0 0 1px;
  margin-bottom: 1rem;
}

/* alerts */
.alert {
  @extend .bg-usic-surface-light, .border, .mat-caption;
  color: mat.m2-get-color-from-palette(theme.$usic-warn) !important;
  margin: 1rem 0 2rem;
  padding: 1rem;
  overflow: auto
}

.highlight-text {
  background-color: var(--usic-green);

  @media (prefers-color-scheme: dark) {
    background-color: var(--usic-blue);
  }
}

.app-header,
.app-footer {
  z-index: 1000;
  width: 100vw;
}

// .app-footer {
//   background-color: var(--usic-white);
//   @media (prefers-color-scheme: dark) {
//     background-color: #000;
//   }
// }

/* background colors */
.bg-usic {
  &-green {
    background-color: var(--usic-green);
    color: var(--usic-white);
  }

  &-grey {
    background-color: var(--usic-grey);
    color: var(--usic-white);
  }

  &-light-grey {
    background-color: rgba(var(--usic-grey), .17);
    color: var(--usic-white);
  }

  &-blue {
    color: var(--usic-white);
    background-color: var(--usic-blue);

    @media (prefers-color-scheme: dark) {
      background-color: var(--usic-blue-dark);
    }
  }

  &-light-blue {
    background-color: rgba(var(--usic-blue), .17);
    color: var(--usic-blue)
  }

  &-black {
    background-color: var(--usic-black);
    color: var(--usic-white);

    @media (prefers-color-scheme: dark) {
      background-color: var(--usic-white);
      color: var(--usic-black);
    }
  }

  &-red {
    background-color: var(--usic-red);
    color: var(--usic-white);

    @media (prefers-color-scheme: dark) {
      color: var(--usic-black);
    }
  }

  &-white {
    background-color: var(--usic-white);
    color: var(--usic-black);

    @media (prefers-color-scheme: dark) {
      background-color: var(--usic-black);
      color: var(--usic-white);
    }
  }

  &-surface {
    background-color: var(--usic-grey);
    color: var(--usic-white);

    @media (prefers-color-scheme: dark) {
      color: var(--usic-black);
    }

    &-light {
      background-color: var(--usic-light-grey) !important;
      color: var(--usic-black);

      @media (prefers-color-scheme: dark) {
        background-color: var(--usic-black) !important;
        color: var(--usic-light-grey);
      }
    }

    ;

    &-dark {
      background-color: var(--usic-black);
      color: var(--usic-white);

      @media (prefers-color-scheme: dark) {
        background-color: var(--usic-white) !important;
        color: var(--usic-black);
      }
    }

    ;
  }

  &-warn {
    background-color: var(--usic-red);
    color: var(--usic-white);

    @media (prefers-color-scheme: dark) {
      color: var(--usic-black);
    }

    &-light {
      background-color: rgba(var(--usic-red-rgb), 0.1) !important;
      color: var(--usic-black) !important;

      @media (prefers-color-scheme: dark) {
        background-color: rgba(252, 221, 212, 1) !important;
        color: var(--usic-white);

        .mdc-checkbox__background {
          border-color: var(--usic-black) !important;
        }
      }
    }

    ;

    /*&-dark {
        background-color: color.adjust(var(--usic-red-rgb), $lightness: -10%)!important;

        color: var(--usic-white) };*/
  }
}

/* borders */
.border {
  border-radius: var(--mdc-dialog-container-shape, 4px);

  border: 1px solid #ccc;

  @media (prefers-color-scheme: dark) {
    border-color: #333;
  }

  &-top {
    border-top: 1px solid #ccc;

    @media (prefers-color-scheme: dark) {
      border-top-color: #333;
    }
  }

  &-right {
    border-right: 1px solid #ccc;

    @media (prefers-color-scheme: dark) {
      border-right-color: #333;
    }
  }

  &-bottom {
    border-bottom: 1px solid #ccc;

    @media (prefers-color-scheme: dark) {
      border-bottom-color: #333;
    }
  }

  &-left {
    border-left: 1px solid #ccc;

    @media (prefers-color-scheme: dark) {
      border-left-color: #333;
    }
  }

  &-light {
    border: 1px solid rgba(#fff, .27);

    @media (prefers-color-scheme: dark) {
      border-top-color: rgba(#000, .73);
    }


    &-top {
      @extend .border-light;
      border-width: 1px 0 0 0;
    }

    &-right {
      @extend .border-light;
      border-width: 0 1px 0 0;
    }

    &-bottom {
      @extend .border-light;
      border-width: 0 0 1px 0;
    }

    &-left {
      @extend .border-light;
      border-width: 0 0 0 1px;
    }
  }

  &-accent {
    border-color: var(--usic-green)
  }
}


/* panels */
.error-panel {
  @extend .bg-usic-warn-light, .border;
  margin: 2rem 0;
  padding: 1.5rem 2rem 1rem;

  .error-title {
    color: inherit;
    font-size: mat.m2-font-size(theme.$usic-card-typography, 'headline-5');
    margin: 0 0 1em;
  }

  .errors {
    margin: 0 0 0 1.5rem;
    padding: 0;
  }

  .error {
    margin: 0 0 .25rem;
    padding-left: 1rem;
  }
}

.info-panel {
  @extend .bg-usic-surface-light, .border;
  margin: 2rem 0;
  padding: 1.25rem 2rem 1rem;

  .error-title {
    color: inherit;
    font-size: mat.m2-font-size(theme.$usic-card-typography, 'headline-5');
    margin: 0 0 1em;
  }

  .errors {
    margin: 0 0 0 1.5rem;
    padding: 0;
  }

  .error {
    margin: 0 0 .25rem;
    padding-left: 1rem;
  }
}

/* margins */
.margin {
  margin: 1rem !important;

  &-top {
    margin-top: 1rem !important;
  }

  &-right {
    margin-right: 1rem !important;
  }

  &-bottom {
    margin-bottom: 1rem !important;
  }

  &-left {
    margin-left: 1rem !important;
  }
}

.small-margin {
  margin: 0.5rem !important;

  &-top {
    margin-top: 0.5rem !important;
  }

  &-right {
    margin-right: 0.5rem !important;
  }

  &-bottom {
    margin-bottom: 0.5rem !important;
  }

  &-left {
    margin-left: 0.5rem !important;
  }
}

.no-margin {
  margin: 0;

  &-top {
    margin-top: 0 !important;
  }

  &-right {
    margin-right: 0 !important;
  }

  &-bottom {
    margin-bottom: 0 !important;
  }

  &-left {
    margin-left: 0 !important;
  }
}

/* padding */
.padding {
  padding: 1rem;

  &-top {
    padding-top: 1rem;
  }

  &-right {
    padding-right: 1rem;
  }

  &-bottom {
    padding-bottom: 1rem;
  }

  &-left {
    padding-left: 1rem;
  }
}

.no-padding {
  padding: 0 !important;

  &-top {
    padding-top: 0 !important;
  }

  &-right {
    padding-right: 0 !important;
  }

  &-bottom {
    padding-bottom: 0 !important;
  }

  &-left {
    padding-left: 0 !important;
  }
}

.scroll {
  overflow: auto;

  &-y {
    overflow-y: auto;
  }

  &-x {
    overflow-x: auto;
  }
}

/* text colors & styling */

.text {
  &-capitalize {
    text-transform: capitalize;
  }

  &-center {
    text-align: center !important;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-weight {
    &-bold {
      font-weight: 700 !important;
    }
  }

  &-fixed-width {
    font-family: "Courier New", Courier, monospace;
  }
}

.text-usic {
  &-green {
    color: var(--usic-green) !important
  }

  &-grey {
    color: var(--usic-grey) !important
  }

  &-blue {
    color: var(--usic-blue) !important;

    @media (prefers-color-scheme: dark) {
      color: var(--usic-blue-dark) !important;
    }
  }

  &-black {
    color: var(--usic-black) !important;

    @media (prefers-color-scheme: dark) {
      color: var(--usic-white) !important;
    }

    &-medium {
      color: rgba(0, 0, 0, .6);

      @media (prefers-color-scheme: dark) {
        color: rgba(255, 255, 255, .6);
      }
    }

  }

  &-red {
    color: var(--usic-red) !important
  }

  &-white {
    color: var(--usic-white) !important;

    @media (prefers-color-scheme: dark) {
      color: var(--usic-black) !important;
    }

    &-medium {
      color: rgba(#fff, .6) !important;

      @media (prefers-color-scheme: dark) {
        color: rgba(#000, .6) !important;
      }
    }
  }
}

/* buttons & labels */
.mat-mdc-button .mdc-button__label,
.mat-mdc-unelevated-button .mdc-button__label,
.mat-mdc-raised-button .mdc-button__label,
.mat-mdc-outlined-button .mdc-button__label {
  white-space: nowrap;
  display: inline-flex;
  gap: .5em;
  align-items: center;
}

.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-unelevated-button,
.mat-mdc-outlined-button {
  border-radius: 0 !important;
  text-transform: uppercase !important;
}

/* text colors for buttons with no backgrounds */
.mat-mdc-button,
.mat-mdc-outlined-button {
  &.mat-white {
    color: var(--usic-white);

    @media (prefers-color-scheme: dark) {
      color: var(--usic-black);
    }
  }

  &.mat-black {
    color: var(--usic-black);

    @media (prefers-color-scheme: dark) {
      color: var(--usic-white);
    }
  }

  &.mat-grey {
    color: var(--usic-grey)
  }

  &.mat-warn {
    color: var(--usic-red)
  }
}

/* background colors & text colors for solid buttons, toolbars & fabs */
.mat-mdc-raised-button,
.mat-mdc-unelevated-button,
.mat-toolbar,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  &.mat-white {
    background-color: var(--usic-white);
    color: var(--usic-black);

    @media (prefers-color-scheme: dark) {
      background-color: var(--usic-black);
      color: var(--usic-white);
    }
  }

  &.mat-black {
    background-color: var(--usic-black);
    color: var(--usic-white);

    @media (prefers-color-scheme: dark) {
      background-color: var(--usic-white);
      color: var(--usic-black);
    }
  }

  &.mat-grey {
    background-color: var(--usic-grey);
    color: var(--usic-white);

    @media (prefers-color-scheme: dark) {
      color: var(--usic-black);
    }
  }

  &.mat-warn {
    background-color: var(--usic-red);
    color: var(--usic-white);

    @media (prefers-color-scheme: dark) {
      color: var(--usic-black);
    }
  }
}

.mat-mdc-raised-button {
  border: 1px solid rgba(0, 0, 0, .12) !important;

  @media (prefers-color-scheme: dark) {
    border-color: 1px solid rgba(255, 255, 255, .88) !important;
  }
}

.mat-mdc-mini-fab {
  line-height: 36px !important;
  width: 36px !important;
  height: 36px !important;

  & .mat-icon {
    height: 36px;
    width: 36px;
    position: relative;
    top: -2px;
  }
}

.mat-mdc-icon-button {

  &.mat-mdc-button-base,
  .material-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px
  }
}

.mat-icon {
  cursor: pointer;

  &.mat-primary {
    color: var(--usic-blue)
  }

  &.mat-accent {
    color: var(--usic-green)
  }

  &.mat-warn {
    color: var(--usic-red)
  }

  &.mat-grey {
    color: var(--usic-grey)
  }

  &.mat-white {
    @media (prefers-color-scheme: dark) {
      color: var(--usic-black);
    }
  }

  &.mat-dark-grey {
    color: var(--usic-black)
  }
}

@media (prefers-color-scheme: dark) {
  .mat-icon-no-color {
    color: var(--usic-white);
  }
}

.mat-mdc-paginator .mat-mdc-input-element {
  border: none;
  background: transparent;
  color: inherit;
  text-align: right;
}

/* .mat-form-field */
.mat-mdc-form-field {

  &.mat-form-field-appearance-outline .mdc-notched-outline {

    &__leading,
    &__notch,
    &__trailing {
      background-color: inherit;

    }
  }

  /*  TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  &.mat-form-field-appearance-outline .mat-form-field-suffix {
    position: absolute;
    right: 0;
    transform: translateY(25%)
  }

  /* disabled form element text color */
  .mat-mdc-select-disabled,
  .mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
    color: rgba(0, 0, 0, .54);

    @media (prefers-color-scheme: dark) {
      color: rgba(255, 255, 255, .54);
    }
  }

  &.mat-form-field-appearance-fill {

    position: relative;

    /* form field indicator size & position */
    .mdc-line-ripple::before,
    .mdc-line-ripple {
      position: absolute;
      left: 0;
      bottom: 0;
      border: solid;
      border-width: 0 0 0 2px;
      height: 100%;
    }

    /* fill-able form field indicator (not disabled) */
    .mdc-line-ripple::before,
    .mdc-line-ripple {
      border-color: color.adjust(mat.m2-get-color-from-palette(theme.$usic-accent), $lightness: -5%);

      @media (prefers-color-scheme: dark) {
        border-color: color.adjust(mat.m2-get-color-from-palette(theme.$usic-accent), $lightness: 5%);
      }
    }

    &.mat-form-field-invalid .mdc-line-ripple::before,
    &.mat-form-field-invalid .mdc-line-ripple {
      border-color: color.adjust(mat.m2-get-color-from-palette(theme.$usic-warn), $lightness: -5%);

      @media (prefers-color-scheme: dark) {
        border-color: color.adjust(mat.m2-get-color-from-palette(theme.$usic-warn), $lightness: 5%);
      }
    }

    &.mat-form-field-disabled .mdc-line-ripple::before,
    &.mat-form-field-disabled .mdc-line-ripple {
      border-color: color.adjust(mat.m2-get-color-from-palette(theme.$usic-surface), $lightness: -5%);

      @media (prefers-color-scheme: dark) {
        border-color: color.adjust(mat.m2-get-color-from-palette(theme.$usic-surface), $lightness: 5%);
      }
    }

    /* fill-able form field indicator (disabled) */
    /*  TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    &.mat-form-field-disabled .mat-form-field-underline::before {
      background-color: rgba(0, 0, 0, .42);

      @media (prefers-color-scheme: dark) {
        background-color: rgba(255, 255, 255, .42);
      }
    }

    .mdc-text-field--disabled.mdc-text-field--filled {
      background-color: rgba(255, 255, 255, .8);
      border: 1px solid #ccc;

      @media (prefers-color-scheme: dark) {
        background-color: rgba(0, 0, 0, .8);
        border-color: #333;
      }
    }

  }

  &.mat-form-field-appearance-outline[color="transparent"] {

    &,
    & .mat-mdc-floating-label,
    & .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-text-field__input {
      cursor: default;
    }

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: #ddd;
      background-color: mat.m2-get-color-from-palette(theme.$usic-surface, "lighter");

      @media (prefers-color-scheme: dark) {
        border-color: #111;
        background-color: mat.m2-get-color-from-palette(theme.$usic-surface, "darker");
      }
    }

  }

}

/* mat-list */
.mat-mdc-list-base {
  .mat-mdc-list-item {
    font-size: mat.m2-font-size(theme.$usic-typography, body-1);
    //@include mat-line-base(mat.font-size($usic-typography, body-1));
  }

  /*  TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  .mat-list-option {
    font-size: mat.m2-font-size(theme.$usic-typography, body-1);
    //@include mat-line-base(mat.font-size(theme.$usic-typography, body-1));
  }

  .mat-mdc-subheader {
    font-family: mat.m2-font-family(theme.$usic-typography, body-2);
    font-size: mat.m2-font-size(theme.$usic-typography, body-2);
    font-weight: mat.m2-font-weight(theme.$usic-typography, body-2);
  }

  &.has-icons {

    /*  TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
    .mat-mdc-list-item .mat-list-item-content {
      padding: 0
    }

    .mat-mdc-subheader {
      white-space: nowrap
    }
  }
}

/*  TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
.icons .mat-mdc-list-base .mat-mdc-list-item .mat-list-item-content {
  padding: 0;
}

/* mat overrides */
.mat-calendar {
  background-color: var(--usic-white);

  @media (prefers-color-scheme: dark) {
    filter: invert(1);
  }
}



/*  TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
label.mat-checkbox-layout {
  margin: 0;
}

.mat-mdc-card-header {
  flex: 1 1 100%;
  justify-content: space-between;
}

.mat-mdc-card.video {
  cursor: pointer;
  text-align: center;
  margin-bottom: 1rem;

  /*  TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
  .mat-card-header-text {
    width: 100%;
    text-align: center;

    .mat-mdc-card-title {
      font-size: 1.5rem;
    }

  }

  .mat-mdc-card-content {
    video {
      max-height: 50vh;
      max-width: 50vw;
    }

  }

}

.mat-mdc-chip:not(.mat-mdc-basic-chip) {
  border-radius: .25rem !important;
  font: mat.m2-font-family(theme.$usic-typography, "body-2");
  height: 1.4em;

  color: mat.m2-get-color-from-palette(theme.$usic-colors, 700);
  background-color: mat.m2-get-color-from-palette(theme.$usic-surface, "lighter");
  border: 1px solid rgba(mat.m2-get-color-from-palette(theme.$usic-surface), .27);

  @media (prefers-color-scheme: dark) {
    color: mat.m2-get-color-from-palette(theme.$usic-colors-dark, 700);
    background-color: mat.m2-get-color-from-palette(theme.$usic-surface, "darker");
    border-color: rgba(mat.m2-get-color-from-palette(theme.$usic-surface), .73);
  }

  &.active {
    background-color: rgba(mat.m2-get-color-from-palette(theme.$usic-surface, "darker"), .2);

    @media (prefers-color-scheme: dark) {
      background-color: rgba(mat.m2-get-color-from-palette(theme.$usic-surface, "lighter"), .2);
    }
  }

  &:not(.active):hover {
    cursor: pointer;

    background-color: mat.m2-get-color-from-palette(theme.$usic-surface, "lighter");
    color: var(--usic-white);

    @media (prefers-color-scheme: dark) {
      background-color: mat.m2-get-color-from-palette(theme.$usic-surface, "darker");
      color: #000;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .mat-mdc-chip:not(.mat-mdc-basic-chip) {
    filter: invert(1);
  }
}

/*  TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
.mat-chip-list-wrapper {
  align-items: inherit !important;
}

/* mat dialog */
.usic-dialog {

  .mat-mdc-dialog-container {
    border-radius: 0;
    padding: 0;

    background-color: #f0f0f0;

    @media (prefers-color-scheme: dark) {
      background-color: #0f0f0f;

      .mdc-dialog__surface {
        background-color: #0f0f0f;
      }
    }

  }

  .mat-mdc-dialog-title {
    @extend .border-bottom;
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    justify-content: space-between;
    align-items: center;
    margin : 0;
    padding: 1rem;

    background-color: var(--usic-white);

    @media (prefers-color-scheme: dark) {
      background-color: #000;
    }

    h1 {
      font-size: 1.9375rem;
      margin: 0;

      color: mat.m2-get-color-from-palette(theme.$usic-colors, 200);

      @media (prefers-color-scheme: dark) {
        color: mat.m2-get-color-from-palette(theme.$usic-colors-dark, 200);
      }

    }
  }

  .mdc-dialog .mdc-dialog__content {
    margin: 0;
    padding: 0;
    max-height: none;
    height: 100%;

    background-color: var(--usic-white);
    color: rgba(0, 0, 0, .6);

    @media (prefers-color-scheme: dark) {
      background-color: #000;
      color: rgba(255, 255, 255, .6);
    }
  }

  .mat-mdc-dialog-actions {
    @extend .border-top, .mat-elevation-z1;
    margin: 0;
    padding: 1rem;

    background-color: var(--usic-white);

    @media (prefers-color-scheme: dark) {
      background-color: #000;
    }
  }
}

/* mat-divider */
.mat-divider-vertical {
  border-width: 0 1px 0 0;

  border: solid rgba(0, 0, 0, .27);

  @media (prefers-color-scheme: dark) {
    border: solid rgba(255, 255, 255, .73);
  }
}

/* mat error */
.mat-mdc-form-field-error {
  color: mat.m2-get-color-from-palette(theme.$usic-warn );
}



/* mat expansion panel */
.mat-accordion {
  &.usic-accordion {
    .mat-expansion-panel-header-title {
      text-transform: uppercase;
    }

    .mat-expansion-panel {
      @extend .border;

      &:first-of-type,
      &:last-of-type {
        border-radius: 0
      }

      /*  TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      mat-table {
        @extend .border-bottom
      }

      mat-row:last-of-type {
        border-bottom: none
      }

      .table-wrapper {
        @extend .border;
        border-width: 0 1px 1px
      }

      .table-toolbar {
        @extend .border
      }

      /*  TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      mat-header-cell .mat-form-field-label-wrapper {
        font-size: 13px;
        top: -.45em
      }
    }
  }
}

/* mat sidenav */
mat-drawer {
  padding: 1.5rem;
  min-width: 20%
}

mat-drawer-content {
  background-color: var(--usic-white);

  @media (prefers-color-scheme: dark) {
    background-color: black;
  }
}

/*  TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
mat-dialog-container .mat-drawer {
  width: 33%;
}

.mat-mdc-menu-panel {
  max-width: none !important;
}

/* mat paginator */
.usic-paginator {

  .mat-mdc-paginator {

    .mat-mdc-select-value {
      color: rgba(0, 0, 0, .54);

      @media (prefers-color-scheme: dark) {
        color: var(--usic-white);
      }

    }

    &-outer-container {
      border-width: 1px 0;
      padding: 0 0 0 .5rem;
      margin-top: 1rem;

      border: solid #ccc;

      @media (prefers-color-scheme: dark) {
        border-color: #333;
      }
    }

    &-page-size {
      margin-right: 1rem;
    }

    &-page-size-label {
      margin: 0 .5rem 0 0;
    }

    &-range-label {
      margin: 0;
    }

    @media (prefers-color-scheme: dark) {
      background-color: var(--usic-black) !important;
      color: var(--usic-light-grey);
    }

    @media (prefers-color-scheme: dark) {
      .mat-mdc-paginator-page-size-select {
        border-radius: .25rem !important;
        border: 1px solid rgba(mat.m2-get-color-from-palette(theme.$usic-surface), .73);
      }

      .mat-mdc-select-arrow {
        color: rgba(mat.m2-get-color-from-palette(theme.$usic-surface), .73);
      }

      // .mat-mdc-select-value-text {
      //   filter: invert(1);
      // }
    }
  }

}

@media (prefers-color-scheme: dark) {

  .mat-mdc-select-panel {
    background-color: mat.m2-get-color-from-palette(theme.$usic-surface, "darker") !important;

    .mat-mdc-option .mdc-list-item__primary-text {
      color: var(--usic-white);
    }
  }
}



@media screen AND (max-width: 767px) {
  .usic-paginator {

    .mat-mdc-paginator {

      &-container {
        flex-wrap: nowrap;
        justify-content: space-between
      }

      &-range-label {
        display: none
      }

    }

  }
}

/* mat stepper */
.mat-step-label {

  &,
  &-selected {
    font-size: inherit;
    text-transform: uppercase;
  }

}

/* mat-table */
/*  TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
mat-table {

  .mat-sort-header-container {
    box-sizing: border-box;
    padding: .5rem;
    width: 100%
  }

  .mat-sort-header-content {
    align-items: normal;
    flex-direction: column;
    width: 100%
  }

  .mat-mdc-cell {

    a[href^="/"] {
      padding-left: 22px;
      background: url('../img/Document.png') left center no-repeat;
    }

    a[href$=".docx"] {
      background: url('../img/Word.png') left center no-repeat;
    }

    a[href$=".pdf"] {
      background: url('../img/pdf.png') left center no-repeat;
    }

    a[href$=".mov"] {
      background: url('../img/Movie.png') left center no-repeat;
    }

    a[href$=".mp4"] {
      background: url('../img/Movie.png') left center no-repeat;
    }

    a[href$=".avi"] {
      background: url('../img/Movie.png') left center no-repeat;
    }

    a[data-content$=".jpg"] {
      background: url('../img/Image.png') left center no-repeat;

      @media (prefers-color-scheme: dark) {
        background: url('../img/Image-dark.png') left center no-repeat;
      }
    }

    a[data-content$=".png"] {
      background: url('../img/Image.png') left center no-repeat;

      @media (prefers-color-scheme: dark) {
        background: url('../img/Image-dark.png') left center no-repeat;
      }
    }

    a[data-content$=".mp3"] {
      background: url('../img/play-button.png') left center no-repeat;

      @media (prefers-color-scheme: dark) {
        background: url('../img/play-button-dark.png') left center no-repeat;
      }
    }


  }
}

.usic-table.mat-mdc-table {
  background-color: inherit !important;

  .mat-mdc-row,
  mat-footer-row {
    min-height: 44px !important;

    @media screen AND (max-width: 600px) {
      min-height: 36px !important;
    }

    /* material default is 48px */
    &:last-of-type {
      @extend .border-bottom
    }
  }

}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-text-size-adjust: 100%;
  transition: background-color 5000s ease-in-out 0s;

  -webkit-text-fill-color: mat.m2-get-color-from-palette(theme.$usic-surface, "darker");

  @media (prefers-color-scheme: dark) {
    -webkit-text-fill-color: mat.m2-get-color-from-palette(theme.$usic-surface, "lighter");
  }
}

.full-width {
  width: 100%;
}

.heading {
  @extend .padding-top;
}


@media print {
  .no-print {
    display: none;
  }
}

/* addons */
.usic-cron-editor {

  .mdc-tab {
    opacity: .9;

    border: 1px solid #ccc;
    border-radius: 20px 20px 0 0;

    @media (prefers-color-scheme: dark) {
      border-color: #333;
      background-color: #101010;
    }

    .mdc-tab__text-label {
      color: var(--usic-black);

      @media (prefers-color-scheme: dark) {
        color: var(--usic-white);
      }
    }

  }

  .mdc-tab--active {
    background-color: #efefef;

    @media (prefers-color-scheme: dark) {
      background-color: #101010;
    }

    .mdc-tab__text-label {
      color: var(--usic-blue);

      @media (prefers-color-scheme: dark) {
        color: var(--usic-blue-dark) !important;
      }
    }
  }

  .mat-mdc-tab-body {
    @extend .bg-usic-surface-light, .border;
    padding: 0 1rem;
    border-top-width: 0;
    margin-bottom: 1rem;

    .mat-mdc-tab-body-content {
      overflow: unset;
    }

  }

  .mat-form-field-appearance-legacy {

    /*  TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-infix {
      @extend .border;
      padding-left: .5em;
      background-color: var(--usic-white);

      @media (prefers-color-scheme: dark) {
        background-color: #000;
      }
    }

    /*  TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-underline {
      display: none
    }

  }

  /*  TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  mat-radio-button {
    margin: 1rem !important;
  }

  /*  TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  /*  TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-label {
    align-items: baseline;

    /*  TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-container {
      transform: translateY(25%)
    }
  }

  .checkbox-margin {
    margin-bottom: 2rem !important
  }
}

/* Support new lines in tooltip text, and allow for wider tooltip boxes */
.mdc-tooltip--shown .mdc-tooltip__surface,
.mat-mdc-tooltip-show .mdc-tooltip__surface {
  padding: .75em 1em;
  font-weight: normal;
  white-space: pre-line;
  max-width: 50vw !important;
}

.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: block !important;
}

@media print {
  .ticket-print-preview {

    .dialog-header,
    .print-button {
      display: none !important;
    }
  }
}

.cdk-overlay-backdrop-showing {
  backdrop-filter: blur(1.25px);
}

.multi-line-snackbar {
  white-space: pre-wrap;
}

/* Miscellaneous fixes for dark mode */
@media (prefers-color-scheme: dark) {
  .mat-mdc-table {

    .mat-mdc-header-cell {
      filter: invert(1);
      background-color: var(--usic-white) !important;
      border-color: #ccc;

      // ATS-419
      // .mat-mdc-form-field {

      //   &.mat-form-field-appearance-outline .mdc-notched-outline {

      //     &__leading,
      //     &__notch,
      //     &__trailing {
      //       background-color: var(--usic-white);
      //     }

      //   }

      // }

      .mat-mdc-floating-label,
      .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-text-field__input,
      .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
        filter: invert(1);
        color: var(--usic-light-grey) !important;
        caret-color: var(--usic-green);
        --mdc-text-field-ink-color: var(--usic-light-grey);
      }

      .mdc-checkbox__background {
        filter: invert(1);
        border-color: var(--usic-white) !important;
      }

    }

    .mat-mdc-row:not(.bg-usic-warn-light) {
      color: var(--usic-white) !important;
      background-color: var(--usic-black) !important;

      .mdc-checkbox__background {
        border-color: var(--usic-white) !important;
      }
    }

  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled),
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: var(--usic-white) !important;
    background-color: var(--usic-black) !important;
  }

  .mdc-floating-label {
    color: var(--usic-grey) !important;
  }

  .mat-mdc-select-value {
    color: var(--usic-white);
  }

  .mdc-label,
  legend,
  p::not(.bg-usic-black) {
    color: var(--usic-white);
  }

  .mdc-form-field .mat-caption,
  .mat-expansion-panel-content p,
  .mat-expansion-panel-content div,
  .mat-mdc-dialog-container label {
    color: var(--usic-white) !important;
    background-color: var(--usic-black) !important;

    .mat-mdc-table {

      .mat-mdc-header-row {
        filter: invert(1);

        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing
         {
          border-color: #ccc;
        }

        .mat-mdc-floating-label,
        .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-text-field__input,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
          filter: invert(0);
        }

        .mat-mdc-icon-button {
          color: var(--usic-white);
        }

      }
    }

  }

  .mat-expansion-panel-header {
    background-color: var(--usic-black) !important;

    .mat-expansion-panel-header-title {
      color: var(--usic-white) !important;
    }
  }

  .mdc-checkbox__background,
  .mdc-radio__background .mdc-radio__outer-circle {
    border-color: var(--usic-white) !important;
  }

  .mdc-dialog__container {
    //    border: 1px solid var(--usic-white);

    .mdc-dialog__surface {
      background-color: #0f0f0f !important;
    }

    .mat-expansion-panel {
      background-color: mat.m2-get-color-from-palette(theme.$usic-surface, "darker") !important;

      .mat-expansion-panel-header-title {
        color: var(--usic-white);
      }

      .mat-mdc-form-field-infix,
      .mat-mdc-form-field-infix .mat-mdc-input-element {
        background-color: #000 !important;
      }
    }

  }

  .cdk-overlay-pane .mat-mdc-menu-panel {
    background-color: var(--usic-black);

    .mat-mdc-menu-item {
      color: var(--usic-white);

      mat-icon {
        color: var(--usic-white);
      }
    }

    .mat-mdc-menu-item:hover {
      background-color: var(--usic-grey);
    }

  }

  // dark mode for Angular Editor
  .angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button {
    background-color: #474545 !important;
  }

  .ae-font .ae-picker-options {
    background-color: #474545 !important;
  }

  .ae-font .ae-picker-label {
    background-color: #474545 !important;
  }

  .angular-editor-toolbar {
    background-color: #474545 !important;
  }

  .angular-editor-textarea {
    color: var(--usic-white) !important;
  }

  .angular-editor-placeholder {
    color: var(--usic-light-grey) !important;
  }

}

@media screen AND (max-width: 600px) {
  .mat-expansion-panel-header-description {
    flex-grow: unset !important;
  }
}

.offline {
  filter: grayscale(1);
}

.mat-mdc-icon-button.small-button {
  padding: 0;
  width: 32px;
}
