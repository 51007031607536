@use 'sass:color';
@use 'sass:map';
@use '@angular/material' as mat;
@use './usic-toolbar-variables';

@import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR:400,500,700&display=swap');

// font-size, line-height, font-weight, letter-spacing, ($font-family: 'override font')
$usic-typography: mat.m2-define-typography-config(
  $font-family: 'Noto Sans KR, sans-serif',

  $headline-5     : mat.m2-define-typography-level(28px, 1em, 700, $letter-spacing: normal),   // h1 + .mat-headline
  $headline-6        : mat.m2-define-typography-level(21px, 1em, 700, $letter-spacing: normal ),     // h2 + .mat-title
  $subtitle-1 : mat.m2-define-typography-level(20px, 1.1em, 700, $letter-spacing: .025em),    // h3 + .mat-subheading-2
  $body-1 : mat.m2-define-typography-level(17.26px, 1.4em, 400, $letter-spacing: 0.05em), // h4 + mat-subheading-1
  $subtitle-2       : mat.m2-define-typography-level(14.2px, 1.3em, 500, $letter-spacing: 0.25px),  // Bolder body text.
  $body-2       : mat.m2-define-typography-level(16.5px, 1.41em, 400, $letter-spacing: 0.05em), // Base body text.
  $caption      : mat.m2-define-typography-level(13px, 1.4em, 500, $letter-spacing: 0.1em),     // Smaller body and hint text.
  $button       : mat.m2-define-typography-level(14.2px, 1em, 500),                             // Buttons and anchors.
);

$usic-card-typography: mat.m2-define-typography-config(
  $font-family: 'Noto Sans KR, sans-serif',
  $headline-5     : mat.m2-define-typography-level(21px, 1em, 500),   // h1 + .mat-headline
  $headline-6        : mat.m2-define-typography-level(48px, 1em, 700, $letter-spacing: normal ),     // h2 + .mat-title
  $body-2       : mat.m2-define-typography-level(15px, 1.41em, 400, $letter-spacing: 0.05em), // Base body text.
);

$usic-table-typography: mat.m2-define-typography-config(
  $font-family: 'Arial, sans-serif',
  $body-2       : mat.m2-define-typography-level(.875rem), // Base body text.
  $caption      : mat.m2-define-typography-level(13px),     // Smaller body and hint text.
);

@include mat.typography-hierarchy( $usic-typography );
@include mat.all-component-typographies( $usic-typography );

@include mat.elevation-classes();
@include mat.app-background();
@include mat.card-typography( $usic-card-typography );
@include mat.dialog-typography( $usic-card-typography );
@include mat.table-typography( $usic-table-typography );
@include mat.paginator-typography( $usic-table-typography );
@include mat.icon-button-typography( $usic-table-typography );
@include mat.form-field-typography( $usic-table-typography );
@include mat.select-typography( $usic-table-typography );

$usic-colors: (
  50 : #94c83d, // green
  100: #7f7c7f, // grey
  200: #00305B, // blue  (#015aa7 for dark)
  400: #F25C0F, // red
  700: #191616, // black (white alternative would be #e6e9e9)
  contrast: (
    50 : #fff,
    100: #FFFFFF,
    200: #FFFFFF,
    400: #FFFFFF,
    700: #FFFFFF,
  )
);

$usic-colors-dark: (
  50 : #94c83d, // green
  100: #7f7c7f, // grey
  200: #015aa7, // blue
  400: #F25C0F, // red
  700: #fefefe, // white
  900: #191616, // black
  contrast: (
    50 : #191616,
    100: #191616,
    200: #191616,
    400: #191616,
    700: #191616,
    900: #fefefe,
  )

);

$usic-warn: (
  default: #F25C0F,                                 // usic red
  lighter: color.adjust(#F25C0F, $lightness: 35%),  // usic red
  darker : color.adjust(#F25C0F, $lightness: -10%), // usic red
  contrast: (
    default: #fff,
    lighter: map.get($usic-colors, 700),
    darker : #fff
  )
);

$usic-surface: (
  default: #7f7c7f, // usic grey
  lighter: #fefefe, // FAFAFA
  darker : #191616, // usic black
  contrast: (
    default: #fff,
    lighter: #000,
    darker : #fff,
  )
);

$usic-primary: mat.m2-define-palette( $usic-colors, 200 );
$usic-primary-dark: mat.m2-define-palette( $usic-colors-dark, 200 );

$usic-accent:  mat.m2-define-palette( $usic-colors, 50 );

$usic-theme-light: mat.m2-define-light-theme( (
  color: (
    primary: $usic-primary,
    accent: $usic-accent,
    warn: $usic-warn
  ),
  typography: ( $usic-typography )
) );

$usic-theme-dark: mat.m2-define-dark-theme( (
  color: (
    primary: $usic-primary-dark,
    accent: $usic-accent,
    warn: $usic-warn
  ),
  typography: ( $usic-typography )
) );

// Apply the light theme by default
@include mat.all-component-themes($usic-theme-light);

// Apply the dark theme only when the user prefers dark themes
@media (prefers-color-scheme: dark) {
  // Use the `-color` mixins to only apply color styles without reapplying the same
  // typography and density styles.
  @include mat.elevation-classes();
  @include mat.app-background();
  @include mat.button-color($usic-theme-dark);
}

.table-toolbar .mat-mdc-slide-toggle { display: flex }
.mat-mdc-slide-toggle {

  --usic-green: rgb(149 201 61);
  --usic-green-light: rgb(149 201 61 / 50%);

  --mdc-switch-selected-track-color: var(--usic-green-light);
  --mdc-switch-selected-hover-track-color: var(--usic-green-light);
  --mdc-switch-selected-pressed-track-color: var(--usic-green-light);
  --mdc-switch-selected-focus-track-color: var(--usic-green-light);

  --mdc-switch-handle-surface-color: var(--usic-green);
  --mdc-switch-selected-handle-color: var(--usic-green);
  --mdc-switch-selected-hover-handle-color: var(--usic-green);
  --mdc-switch-selected-pressed-handle-color: var(--usic-green);
  --mdc-switch-selected-focus-handle-color: var(--usic-green);

  .mdc-switch + label { white-space: nowrap; line-height : 1; margin : 0; }
}
