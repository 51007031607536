/* flex layout classes - instructions https://material.angularjs.org/latest/layout/introduction */

.layout-align,
.layout-align-start-stretch {
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch
}

.layout-align-start,
.layout-align-start-center,
.layout-align-start-end,
.layout-align-start-start,
.layout-align-start-stretch {
  justify-content: flex-start
}

.layout-align-center,
.layout-align-center-center,
.layout-align-center-end,
.layout-align-center-start,
.layout-align-center-stretch {
  justify-content: center
}

.layout-align-end,
.layout-align-end-center,
.layout-align-end-end,
.layout-align-end-start,
.layout-align-end-stretch {
  justify-content: flex-end
}

.layout-align-space-around,
.layout-align-space-around-center,
.layout-align-space-around-end,
.layout-align-space-around-start,
.layout-align-space-around-stretch {
  justify-content: space-around
}

.layout-align-space-between,
.layout-align-space-between-center,
.layout-align-space-between-end,
.layout-align-space-between-start,
.layout-align-space-between-stretch {
  justify-content: space-between
}

.layout-align-space-evenly,
.layout-align-space-evenly-center,
.layout-align-space-evenly-end,
.layout-align-space-evenly-start,
.layout-align-space-evenly-stretch {
  justify-content: space-evenly
}

.layout-align-center-start,
.layout-align-end-start,
.layout-align-space-around-start,
.layout-align-space-between-start,
.layout-align-start-start,
.layout-align-space-evenly-start {
  align-items: flex-start;
  align-content: flex-start
}

.layout-align-center-center,
.layout-align-end-center,
.layout-align-space-around-center,
.layout-align-space-between-center,
.layout-align-start-center,
.layout-align-space-evenly-center {
  align-items: center;
  align-content: center;
  max-width: 100%
}

.layout-align-center-center>*,
.layout-align-end-center>*,
.layout-align-space-around-center>*,
.layout-align-space-between-center>*,
.layout-align-start-center>*,
.layout-align-space-evenly-center>* {
  max-width: 100%;
  box-sizing: border-box
}

.layout-align-center-end,
.layout-align-end-end,
.layout-align-space-around-end,
.layout-align-space-between-end,
.layout-align-start-end,
.layout-align-space-evenly-end {
  align-items: flex-end;
  align-content: flex-end
}

.layout-align-center-stretch,
.layout-align-end-stretch,
.layout-align-space-around-stretch,
.layout-align-space-between-stretch,
.layout-align-start-stretch,
.layout-align-space-evenly-stretch {
  align-items: stretch;
  align-content: stretch
}

.flex {
  flex: 1 1 0%;
}

.flex,
.flex-grow {
  box-sizing: border-box
}

.flex-grow {
  flex: 1 1 100%
}

.flex-initial {
  flex: 0 1 auto;
  box-sizing: border-box
}

.flex-auto {
  flex: 1 1 auto;
  box-sizing: border-box
}

.flex-none {
  flex: 0 0 auto;
  box-sizing: border-box
}

.flex-noshrink {
  flex: 1 0 auto;
  box-sizing: border-box
}

.flex-nogrow {
  flex: 0 1 auto;
  box-sizing: border-box
}

.flex-0,
.layout-row>.flex-0 {
  flex: 1 1 100%;
  max-width: 0;
  max-height: 100%;
  box-sizing: border-box
}

.layout-row>.flex-0 {
  min-width: 0
}

.layout-column>.flex-0 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 0%;
  box-sizing: border-box
}

.flex-5,
.layout-row>.flex-5 {
  flex: 1 1 100%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-5 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 5%;
  box-sizing: border-box
}

.flex-10,
.layout-row>.flex-10 {
  flex: 1 1 100%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-10 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 10%;
  box-sizing: border-box
}

.flex-15,
.layout-row>.flex-15 {
  flex: 1 1 100%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-15 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 15%;
  box-sizing: border-box
}

.flex-20,
.layout-row>.flex-20 {
  flex: 1 1 100%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-20 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 20%;
  box-sizing: border-box
}

.flex-25,
.layout-row>.flex-25 {
  flex: 1 1 100%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-25 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 25%;
  box-sizing: border-box
}

.flex-30,
.layout-row>.flex-30 {
  flex: 1 1 100%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-30 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 30%;
  box-sizing: border-box
}

.flex-35,
.layout-row>.flex-35 {
  flex: 1 1 100%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-35 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 35%;
  box-sizing: border-box
}

.flex-40,
.layout-row>.flex-40 {
  flex: 1 1 100%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-40 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 40%;
  box-sizing: border-box
}

.flex-45,
.layout-row>.flex-45 {
  flex: 1 1 100%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-45 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 45%;
  box-sizing: border-box
}

.flex-50,
.layout-row>.flex-50 {
  flex: 1 1 100%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-50 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 50%;
  box-sizing: border-box
}

.flex-55,
.layout-row>.flex-55 {
  flex: 1 1 100%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-55 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 55%;
  box-sizing: border-box
}

.flex-60,
.layout-row>.flex-60 {
  flex: 1 1 100%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-60 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 60%;
  box-sizing: border-box
}

.flex-65,
.layout-row>.flex-65 {
  flex: 1 1 100%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-65 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 65%;
  box-sizing: border-box
}

.flex-70,
.layout-row>.flex-70 {
  flex: 1 1 100%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-70 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 70%;
  box-sizing: border-box
}

.flex-75,
.layout-row>.flex-75 {
  flex: 1 1 100%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-75 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 75%;
  box-sizing: border-box
}

.flex-80,
.layout-row>.flex-80 {
  flex: 1 1 100%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-80 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 80%;
  box-sizing: border-box
}

.flex-85,
.layout-row>.flex-85 {
  flex: 1 1 100%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-85 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 85%;
  box-sizing: border-box
}

.flex-90,
.layout-row>.flex-90 {
  flex: 1 1 100%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-90 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 90%;
  box-sizing: border-box
}

.flex-95,
.layout-row>.flex-95 {
  flex: 1 1 100%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-column>.flex-95 {
  max-height: 95%
}

.flex-100,
.layout-column>.flex-95 {
  flex: 1 1 100%;
  max-width: 100%;
  box-sizing: border-box
}

.flex-100 {
  max-height: 100%
}

.layout-column>.flex-100,
.layout-row>.flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box
}

.flex-33 {
  max-width: 33.33%
}

.flex-33,
.flex-66 {
  flex: 1 1 100%;
  max-height: 100%;
  box-sizing: border-box
}

.flex-66 {
  max-width: 66.66%
}

.layout-row>.flex-33 {
  flex: 1 1 33.33%
}

.layout-row>.flex-66 {
  flex: 1 1 66.66%
}

.layout-column>.flex-33 {
  flex: 1 1 33.33%
}

.layout-column>.flex-66 {
  flex: 1 1 66.66%
}

.layout-row>.flex-33 {
  max-width: 33.33%
}

.layout-row>.flex-33,
.layout-row>.flex-66 {
  flex: 1 1 100%;
  max-height: 100%;
  box-sizing: border-box
}

.layout-row>.flex-66 {
  max-width: 66.66%
}

.layout-row>.flex {
  min-width: 0
}

.layout-column>.flex-33 {
  max-height: 33.33%
}

.layout-column>.flex-33,
.layout-column>.flex-66 {
  flex: 1 1 100%;
  max-width: 100%;
  box-sizing: border-box
}

.layout-column>.flex-66 {
  max-height: 66.66%
}

.layout-column>.flex {
  min-height: 0
}

.layout,
.layout-column,
.layout-row {
  box-sizing: border-box;
  display: flex
}

.layout-column {
  flex-direction: column
}

.layout-row {
  flex-direction: row
}

.layout-padding-sm>*,
.layout-padding>.flex-sm {
  padding: 4px
}

.layout-padding,
.layout-padding-gt-sm,
.layout-padding-gt-sm>*,
.layout-padding-md,
.layout-padding-md>*,
.layout-padding>*,
.layout-padding>.flex,
.layout-padding>.flex-gt-sm,
.layout-padding>.flex-md {
  padding: 8px
}

.layout-padding-gt-lg>*,
.layout-padding-gt-md>*,
.layout-padding-lg>*,
.layout-padding>.flex-gt-lg,
.layout-padding>.flex-gt-md,
.layout-padding>.flex-lg {
  padding: 16px
}

.layout-margin-sm>*,
.layout-margin>.flex-sm {
  margin: 4px
}

.layout-margin,
.layout-margin-gt-sm,
.layout-margin-gt-sm>*,
.layout-margin-md,
.layout-margin-md>*,
.layout-margin>*,
.layout-margin>.flex,
.layout-margin>.flex-gt-sm,
.layout-margin>.flex-md {
  margin: 8px
}

.layout-margin-gt-lg>*,
.layout-margin-gt-md>*,
.layout-margin-lg>*,
.layout-margin>.flex-gt-lg,
.layout-margin>.flex-gt-md,
.layout-margin>.flex-lg {
  margin: 16px
}

.layout-wrap {
  flex-wrap: wrap
}

.layout-nowrap {
  flex-wrap: nowrap
}

.layout-fill {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.layout-gap {
  gap: 1rem;

  @media screen AND (max-width: 600px) {
    gap: .15rem;
  }
}

.layout-gap-25 { gap: .25rem }
.layout-gap-half { gap: .5rem; }
